:root {

    /*----lightness Primary Color (#0a539c)---*/
--primary-color-hue: 210; /* Hue of #0a539c */
--primary-color-saturation: 90%; /* Saturation of #0a539c */
--primary-color-lightness: 34%; /* Lightness of #0a539c */
--lightness-adjustment-1: 5%; /* Amount to adjust the lightness */
--lightness-adjustment-2: 20%; /* Amount to adjust the lightness */
--lightness-adjustment-3: 30%; /* Amount to adjust the lightness */
--lightness-adjustment-4: 40%; /* Amount to adjust the lightness */
--lightness-adjustment-5: 45%; /* Amount to adjust the lightness */
--lightness-adjustment-6: 60%; /* Amount to adjust the lightness */
--lightness-adjustment-7: 65%; /* Amount to adjust the lightness */
--lightness-adjustment-8: 70%; /* Amount to adjust the lightness */

    scroll-behavior: smooth;
    --font-family: 'Poppins', sans-serif;
    --font-size: 14px;
    --font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
    --font-variation-settings: normal;
    --text-color: #292929;
    --border-color: #f7f7f7;
    --border-radius: 4px;

    --primary-color: #0a539c;
    --primary-highlight-color: #0c66bf;
    --primary-hover-color: #e7f3ff;

    --success-color: #28a745;
    --success-highlight-color: #2f9e44;
    --success-hover-color: #e7f3ff;

    --danger-color: #dc3545;
    --danger-highlight-color: #c82333;
    --danger-hover-color: #f8d7da;

    --secondary-color: #64748b;
    --secondary-highlight-color: #64748b;
    --secondary-hover-color: #e7f3ff;
}

