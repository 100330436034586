.mlx-btn {
    &.p-button {
        padding: 0.475rem 0.75rem;
    }
    &.p-button.p-button-sm {
        padding: 0.275rem 0.75rem;
        .p-button-label {
            font-size: calc(var(--font-size) - 2px);
        }
    }

    .p-button-label {
        font-size: var(--font-size);
        font-weight: 500;
        letter-spacing: 0.0295rem;
    }

    &.p-button.p-button-outlined {
        background-color: #fff;
    }
}
