.mlx-input-switch {
    width: 3rem;
    height: 1.475rem;
    .p-inputswitch-slider::before {
        background: #ffffff;
        width: 1.05rem;
        height: 1.05rem;
        left: 0.25rem;
        margin-top: -0.525rem;
        border-radius: 50%;
        transition-duration: 0.2s;
    }
}
