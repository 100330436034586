.profile-image-form {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    .p-avatar {
        position: relative;
        width: 120px;
        height: 120px;
        border: 2px solid var(--primary-color);
    }
    .p-button {
        position: absolute;
        bottom: 5px;
        width: 2rem;
        height: 2rem;
        border: none;
    }
    .add-icon {
        right: -5px;
        background-color: var(--primary-highlight-color);
    }
    .edit-icon {
        right: -5px;
        background-color: var(--primary-highlight-color);
    }
    .delete-icon {
        left: -5px;
        background-color: var(--danger-highlight-color);
    }
}
.add-user-form {
    .username-field {
        padding: 1rem 0.5rem;
        background-color: var(--secondary-hover-color);
        border-radius: 5px;
    }
}
