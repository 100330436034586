.login-page-container {
    .login-form {
        max-width: 300px;
    }
    .login-page-img {
        background-image: url("../../assets/images/login-bg.jpg");
        background-size: contain;
        background-color: rgba($color: #fefefe, $alpha: 0.25);
        background-blend-mode: overlay;

        .login-form {
            max-width: 300px;
        }
    }
}
