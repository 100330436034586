.mlx-sidebar {
    width: 550px;
    position: relative;

    .mlx-sidebar-header {
        font-weight: 600;
        font-size: calc(var(--font-size) + 2px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-bottom: 1px solid var(--border-color);
        background-color: var(--primary-hover-color);
    }

    .mlx-sidebar-body {
        padding: 1rem 1rem 4rem 1rem;
        overflow-y: auto;
    }

    .mlx-sidebar-footer {
        background-color: var(--primary-hover-color);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0.865rem;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .p-blockui-container{
        display: flex;
        flex-direction: column;  
    }
}
