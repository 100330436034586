@import './abstracts/typography.scss';
@import './abstracts/variables.scss';

@import 'primeicons/primeicons.css';
@import 'remixicon/fonts/remixicon.css';

@import '../styles/bootstrap.min.css' layer(bootstrap);
@import 'theme.scss' layer(custom-theme);

@layer bootstrap, custom-theme;

@import './common.scss';
