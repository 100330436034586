.image-layout {
    position: relative;
    width: 160px;
    height: 160px;
    background-color: var(--bs-primary-bg-subtle);
    border: 1px dashed var(--bs-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    .p-button-icon {
        font-size: 2rem;
        color: var(--primary-color);
    }

    .remove-image-btn {
        width: 2rem;
        height: 2rem;
        position: absolute;
        bottom: -1rem;
        right: -1rem;
        background: var(--bs-primary-bg-subtle);
        box-shadow:
            0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 1px 5px 0 rgba(0, 0, 0, 0.12);
        .p-button-icon {
            font-size: 1rem;
            font-weight: bold;
            color: var(--danger-color);
        }
    }
}

.custom-logo-upload {
    .p-fileupload-choose {
        background: none;
        border: none;
        padding: 4.5rem;
        .p-button-icon {
            margin: 0 auto;
        }
        .p-button-label {
            display: none;
        }

        &:focus {
            box-shadow: none;
        }
    }
}
