.sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    //box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    border-right: 1px solid hsl(220, 10%, calc(17% + 70%));
    background-color: #fff;
}

@media (max-width: 1199px) {
    .sidebar {
        left: -300px;
    }
}

.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

@media (min-width: 1200px) {
    #main,
    #footer {
        margin-left: 300px;
    }
}

@media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
        left: 0;
    }
}

@media (min-width: 1200px) {
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;
    }

    .toggle-sidebar .sidebar {
        left: -300px;
    }
}

.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-item {
    margin-bottom: 10px;
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: baseline;
    color: var(--primary-color);
    font-size: calc(var(--font-size) + 2px);
    font-weight: 500;
    padding: 10px 15px;
}

.sidebar-nav .nav-link i {
    font-size: 20px;
    margin-right: 10px;
    font-weight: normal;
    margin-top: -2px;
}

.sidebar-nav .nav-link.collapsed {
    color: var(--primary-color);
    background: #fff;
}

.sidebar-nav .nav-link i {
    color: #899bbd;
}

.sidebar-nav .nav-link:hover {
    color: var(--primary-color);
    background: var(--primary-hover-color);
}

.sidebar-nav .nav-link:hover i {
    color: var(--primary-color);
}

.sidebar-nav .nav-link .ri-arrow-down-s-line {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .ri-arrow-down-s-line {
    transform: rotate(180deg);
}

.sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-content button {
    display: flex;
    align-items: center;
    font-size: calc(var(--font-size) + 2px);
    font-weight: 500;
    color: var(--primary-color);
    transition: 0.3;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
}

.sidebar-nav button:hover,
.sidebar-nav button.active {
    color: var(--primary-color);
}

button.active {
    background-color: var(--primary-hover-color) !important;
}
