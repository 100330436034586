#main {
    background-color: #f5f5fd;
    height: inherit;
    padding: 15px 20px;
    transition: all 0.3s;
    padding-top: 75px;
    height: 100vh;
    overflow-y: scroll;
}

@media (max-width: 1199px) {
    #main {
        // padding: 20px;
    }
}
