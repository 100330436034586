.register-page-container {
    .register-form {
        max-width: 320px;

        .input-username {
            position: relative;

            input {
                padding-right: 2.25rem;
            }

            i {
                position: absolute;
                top: 0.85rem;
                right: 1.15rem;
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }
    .register-page-img {
        background-image: url("../../assets/images/login-bg.jpg");
        background-size: contain;
        background-color: rgba($color: #fefefe, $alpha: 0.25);
        background-blend-mode: overlay;
    }
}
