.step-container {
    display: flex;
    flex-direction: column;
    .step-number {
        letter-spacing: 0.565px;
        font-weight: 600;
        font-size: var(--font-size);
        color: grey;
    }
    .step-title {
        color: var(--primary-color);
        margin-top: 10px;
        font-weight: 600;
        font-size: calc(var(--font-size) + 4px);
    }
    .step-note {
        font-weight: 500;
        font-size: calc(var(--font-size) - 2.5px);
        margin-top: 4.5px;
    }
}

.step2-container-1 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    .form-field {
        font-size: var(--font-size);
    }
    .venue-url-container {
        margin-top: 1.25rem;
        font-size: calc(var(--font-size) - 4px);
        display: flex;
        flex-direction: column;
        background-color: var(--bs-danger-bg-subtle);
        padding: 1rem;
        border: 1px dashed var(--bs-danger);
        .note {
            color: var(--bs-danger);
        }
    }
}

.step-container-footer {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.step3-container-form {
    margin-top: 2rem;
    font-size: var(--font-size);
}

.step4-container-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2rem;
}

.onboard-bg-img {
    background-image: url("../../assets/images/login-bg.jpg");
    background-size: contain;
    background-color: rgba($color: #fefefe, $alpha: 0.25);
    background-blend-mode: overlay;
}
