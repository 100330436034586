.content-body {
    .row {
        background-color: #f5f5fd;
    }
    .permission-body {
        background-color: #fff;
        border-radius: 6px;
    }
    .accordion-no-children a svg {
        visibility: hidden;
    }

    .roles-link {
        margin: 0;
        padding-left: 0rem;
        li {
            align-items: center;
            background: #f5f5fd;
            border-radius: 5px;
            color: var(--primary-color);
            display: flex;
            justify-content: space-between;
            list-style: none;
            margin-bottom: 10px;
            padding: 0.5rem;

            &.active {
                background: var(--primary-color);
                color: #fff;
                transition: all;
            }
        }
    }
    .permission-footer {
        position: sticky;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        background: var(--primary-hover-color);
        justify-self: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
    }
}
