.customLangTranslate {
    margin-right: 1.5rem;

    .country-item {
        img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }

        span {
            margin-left: 0.5rem;
        }
    }

    &.p-dropdown {
        border: none;
        border-radius: 50%;
    }

    .p-dropdown-label {
        padding: 0;
    }

    .p-dropdown-trigger {
        display: none;
    }

    .p-dropdown-panel {
        left: -5px !important;

        .p-dropdown-item {
            padding: 0.75rem 1rem;
            display: flex;
            justify-content: flex-start;
        }
    }
}

@media (max-width: 382.98px) {
    .customLangTranslate {
        .country-item {
            img {
                width: 20px !important;
                height: 20px !important;
            }
        }
    }
}
