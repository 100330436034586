.mlx-drop-down {
    .p-inputtext {
        padding: 0.475rem 0.75rem;
    }
    .p-dropdown-filter {
        padding: 0.475rem 0.75rem;
    }

    &.small-dropdown {
        .p-inputtext {
            padding: 0.275rem 0.75rem;
        }
        .p-dropdown-trigger {
            width: 2rem;
            svg {
                width: 0.75rem;
            }
        }
        .p-dropdown-filter {
            padding: 0.275rem 0.75rem;
        }
    }
}
