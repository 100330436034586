.map-container {
    position: relative;

    .gm-fullscreen-control {
        top: 82% !important;
        right: 5px !important;
        bottom: 0px !important;
    }

    .map-search {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 9;
        width: 90%;

        input {
            width: 100%;
        }
    }
    .gmnoprint {
        display: none;
    }
}
