.header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    //box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    border-bottom: 1px solid hsl(220, 10%, calc(17% + 70%));
    background-color: #fff;
    //padding-left: 20px;
    /* Toggle Sidebar Button */
    /* Search Bar */
}

.header .toggle-sidebar-btn {
    font-size: 25px;
    padding-left: 10px;
    cursor: pointer;
    color: var(--primary-color);
}

.header-nav {
    .p-avatar {
        img {
            width: 3rem;
            height: 3rem;
        }
    }

    .profile-info {
        line-height: normal;
    }
}

.header-dropdown-panel {
    &::before {
        left: 0;
        content: none;
    }

    .p-overlaypanel-content {
        padding: 0px;
        .p-menu {
            padding: 0px;
            .p-menu-list {
                .p-menuitem-content:hover {
                    background: var(--primary-hover-color);
                }
                .p-menuitem:not(:last-child) {
                    border-bottom: 1px solid var(--border-color);
                }
            }
        }
    }
}
