.profile-image-form {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    .p-avatar {
        position: relative;
        width: 120px;
        height: 120px;
    }
    .p-button {
        position: absolute;
        bottom: 5px;
        width: 2rem;
        height: 2rem;
        border: none;
    }
    .add-icon {
        right: -5px;
        background-color: var(--primary-highlight-color);
    }
    .edit-icon {
        right: -5px;
        background-color: var(--primary-highlight-color);
    }
    .delete-icon {
        left: -5px;
        background-color: var(--danger-highlight-color);
    }
}
.add-staff-form {
    .username-field {
        --adjusted-lightness: calc(var(--primary-color-lightness) + var(--lightness-adjustment-7)) !important;
        padding: 1rem 0.5rem;
        //background-color: var(--secondary-hover-color);
        background-color: hsl(var(--primary-color-hue), var(--primary-color-saturation), var(--adjusted-lightness)) !important;;
        border-radius: 5px;
    }
}

.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}