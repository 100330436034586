@font-face {
    font-family: 'Poppins-Bold';
    src:
        url('../../fonts/Poppins-Bold.ttf') format('truetype'),
        url('../../fonts/poppins-bold-webfont.woff') format('woff'),
        url('../../fonts/poppins-bold-webfont.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src:
        url('../../fonts/Poppins-SemiBold.ttf') format('truetype'),
        url('../../fonts/poppins-semibold-webfont.woff') format('woff'),
        url('../../fonts/poppins-semibold-webfont.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src:
        url('../../fonts/Poppins-Medium.ttf') format('truetype'),
        url('../../fonts/poppins-medium-webfont.woff') format('woff'),
        url('../../fonts/poppins-medium-webfont.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src:
        url('../../fonts/Poppins-Regular.ttf') format('truetype'),
        url('../../fonts/poppins-regular-webfont.woff') format('woff'),
        url('../../fonts/poppins-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
