html,
body {
    margin: 0;
    height: 100%;
    width: 100%;

    background: #fff;
    position: relative;

    font-size: var(--font-size);
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings);
    font-variation-settings: var(--font-variation-settings);
    font-weight: normal;
    word-wrap: break-word !important;
    word-break: break-word !important;
    line-height: 1.5rem;
    color: var(--text-color);
}

#root,
.App {
    height: inherit;
    width: inherit;
}

.small,
small {
    font-size: 0.775em;
}

.page-section {
    height: 100%;
    display: flex;
    flex-direction: column;

    .page-header {
        flex-grow: 0;
        font-size: 1.25rem;
        font-weight: 600;
        padding-bottom: 15px;
        margin: 0;
    }

    .page-content {
        --bs-bg-opacity: 1;
        display: flex;
        flex-direction: column;
        flex-grow: 1 !important;
        background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
        border-radius: var(--bs-border-radius) !important;

        .content-header {
            flex-grow: 0;
            padding: 1rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .header-title {
                font-size: 1.05rem;
                font-weight: 600;
            }
            .header-fields {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .content-divider {
            margin: 0px;
            padding: 0px;
        }

        .content-body {
            flex-grow: 1;
        }
    }
}

.custom-p-table {
    padding: 0.375rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    .p-datatable-wrapper {
        flex-grow: 1;
        .p-datatable-thead {
            th {
                padding: 1rem 0.5rem;
            }
        }
    }
    .p-datatable-tbody {
        font-size: var(--font-size);
        td {
            padding: 0.675rem 0.5rem;
        }
    }
}

.custom-input-text {
    &.p-inputtext {
        padding: 0.475rem 0.75rem;
    }
    &.p-inputtext-sm {
        padding: 0.275rem 0.75rem;
    }
}

.p-checkbox {
    cursor: pointer;
    .p-checkbox-box {
        &.p-component.p-highlight {
            border-color: var(--primary-highlight-color);
            background: var(--primary-color);
        }
    }

    &.size-small {
        width: 17px;
        height: 17px;
        .p-checkbox-box {
            width: 17px;
            height: 17px;
        }
    }
}

.p-fieldset {
    all: revert;
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: var(--secondary-color);
    border-radius: var(--border-radius);
    .p-fieldset-legend {
        all: revert;
        padding: 0.75rem;
        border: 1px solid #e5e7eb;
        color: var(--secondary-color);
        background: #f9fafb;
        font-weight: 700;
        border-radius: var(--border-radius);
    }
}

// Scroll bar stylings
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-track {
    background: #fff;
}
::-webkit-scrollbar-thumb {
    background: var(--primary-hover-color);
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: var(--primary-color);
}

.mlx-confirm-popup {
    .p-confirm-popup-footer {
        button {
            padding: 0.45rem 1.15rem !important;
        }

        .p-confirm-popup-accept {
            margin-left: 1.5rem;
        }
    }
}

.mlx-confirm-dialog {
    .p-dialog-footer {
        button {
            padding: 0.45rem 1.15rem !important;
        }

        .p-confirm-dialog-accept {
            margin-left: 1.5rem;
        }
    }
}
