.permission-org-accordion-tab {
    position: relative;

    .form-switch {
        position: absolute;
        z-index: 1;
    }
    .permission-name {
        margin-left: 2.5rem;
        margin-top: -1.5px;
    }
}
