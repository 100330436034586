.mlx-search {
    max-width: 200px;

    .p-inputtext {
        padding: 0.475rem 0.75rem 0.475rem 2.5rem;
        max-width: 200px;
        &.show-icon-only {
            padding: 0.275rem 0.75rem 0.275rem 2.5rem;
            max-width: 200px;
        }
    }

    &.p-inputtext-sm {
        .p-inputtext {
            padding: 0.275rem 0.75rem 0.275rem 2.5rem;
            max-width: 200px;
            &.show-icon-only {
                padding: 0.275rem 1.25rem;
                max-width: 0px;
            }
        }
    }
}
